<template>
  <div id="app">
    <HeaderView></HeaderView>
    <router-view />
    <FooterView></FooterView>
    <WalletModal></WalletModal>
  </div>
</template>
<script>
import HeaderView from './views/HeaderView.vue';
import FooterView from './views/FooterView.vue';
import WalletModal from './components/WalletModal.vue';
export default {
  name: 'App',
  components: { HeaderView, FooterView, WalletModal},
  data() {
    return {

    }
  },
  methods: {

  },


}
</script>

<style lang="scss">

.progress {
  background-color: #343a40 !important;
}
.v-toast__item .v-toast__text{
  padding: 15px!important;
  color: white !important;
  font-size: 14px!important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
</style>
