<template>

  <header class="rn-header haeder-default header--sticky" :class="sticky ? 'sticky' : ''">

    <div class="container">
      <div class="header-inner" style="display: flex;">
        <div class="header-left">
          <div class="logo-thumbnail logo-custom-css">
            <a class="logo-light" href="/"><img src="/assets/images/logo/logo-white.png?v=1.0.1" alt="lock-logo"></a>
            <a class="logo-dark" href="/"><img src="/assets/images/logo/logo-dark.png?v=1.0.1" alt="lock-logo"></a>
          </div>
          <div class="mainmenu-wrapper">
            <nav id="sideNav" class="mainmenu-nav d-none d-xl-block">
              <!-- Start Mainmanu Nav -->
              <ul class="mainmenu">

                <li><router-link :class="$route.name === 'home' ? 'active' : ''" to="/">Mint</router-link></li>
                <li><router-link :class="$route.name === 'airdrop' ? 'active' : ''" to="/airdrop">Airdrop</router-link>
                </li>
                <li><router-link :class="$route.name === 'account' ? 'active' : ''" to="/account">Account</router-link>
                </li>
                <li><router-link :class="$route.name === 'marketplace' ? 'active' : ''"
                    to="/marketplace">Marketplace</router-link></li>

              </ul>
              <!-- End Mainmanu Nav -->
            </nav>
          </div>
        </div>
        <div class="header-right">
          <!-- <div class="setting-option d-none d-lg-block">
            <form class="search-form-wrapper" action="#">
              <input type="search" placeholder="Search Here" aria-label="Search">
              <div class="search-icon">
                <button><i class="feather-search"></i></button>
              </div>
            </form>
          </div> -->
          <!-- <div class="setting-option rn-icon-list d-block d-lg-none">
            <div class="icon-box search-mobile-icon">
              <button><i class="feather-search"></i></button>
            </div>
            <form id="header-search-1" action="#" method="GET" class="large-mobile-blog-search">
              <div class="rn-search-mobile form-group">
                <button type="submit" class="search-button"><i class="feather-search"></i></button>
                <input type="text" placeholder="Search ...">
              </div>
            </form>
          </div> -->

          <div class="setting-option header-btn rbt-site-header" v-if="!$store.state.wallet.address">
            <div class="icon-box">
              <a id="connectbtn" class="btn btn-primary-alta btn-small" @click="showWalletModal">Connect wallet</a>
            </div>
          </div>

          <div class="setting-option rn-icon-list notification-badge">
            <div class="icon-box">
              <router-link to="/bonus"><i class="feather-bell"></i><span
                  v-show="$store.state.wallet.address && $store.state.bonusCount > 0" class="badge bg-danger">{{
    $store.state.bonusCount }}</span></router-link>
            </div>
          </div>

          <div v-if="$store.state.wallet.address">
            <div class="setting-option rn-icon-list user-account">
              <div class="icon-box">
                <a><img :src="$common.getColorAvatar($store.state.wallet.address)" alt=""></a>
                <div class="rn-dropdown">
                  <div class="rn-inner-top">
                    <h4 class="title"><a href="javascript:" @click="$common.copyText($store.state.wallet.address)">{{
    $store.getters.formatAddress }} <i class="feather-copy"></i></a></h4>
                    <!-- <span><a href="#"><i class="feather-copy"></i> Copy Address</a></span> -->
                  </div>
                  <div class="rn-product-inner">
                    <ul class="product-list">
                      <li class="single-product-list">
                        <div class="thumbnail">
                          <a href="javascript:"><img src="/assets/images/lock/locknft.png" alt=""></a>
                        </div>
                        <div class="content">
                          <h6 class="title"><a href="javascript:">SPL403 Balance</a></h6>
                          <span class="price">{{ $store.state.balance.toLocaleString() }}</span>
                        </div>
                        <div class="button"></div>
                      </li>
                      <li class="single-product-list">
                        <div class="thumbnail">
                          <span class="badge bg-danger" v-show="$store.state.chips[0] > 0">{{ $store.state.chips[0]
                            }}</span>
                          <router-link class="thumbnail-image" to="/airdrop"><img src="/assets/images/lock/lock0.jpg"
                              alt=""></router-link>
                        </div>
                        <div class="thumbnail">
                          <span class="badge bg-danger" v-show="$store.state.chips[1] > 0">{{ $store.state.chips[1]
                            }}</span>
                          <router-link class="thumbnail-image" to="/airdrop"><img src="/assets/images/lock/lock1.jpg"
                              alt=""></router-link>
                        </div>
                        <div class="thumbnail">
                          <span class="badge bg-danger" v-show="$store.state.chips[2] > 0">{{ $store.state.chips[2]
                            }}</span>
                          <router-link class="thumbnail-image" to="/airdrop"><img src="/assets/images/lock/lock2.jpg"
                              alt=""></router-link>
                        </div>
                        <div class="button"></div>
                      </li>
                    </ul>
                  </div>
                  <div class="add-fund-button mt--20 pb--20">
                    <router-link class="btn btn-primary-alta w-100" to="/airdrop">Get More Lock Chips</router-link>
                  </div>
                  <ul class="list-inner">
                    <li><router-link to="/bonus">My Chips</router-link></li>
                    <li><router-link to="/account">My Account</router-link></li>
                    <!-- <li><a href="connect.html">Manage funds</a></li> -->
                    <li><a @click="disconnectWallet">Disconnect</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


          <div class="setting-option mobile-menu-bar d-block d-xl-none">
            <div class="hamberger">
              <button class="hamberger-button">
                <i class="feather-menu"></i>
              </button>
            </div>
          </div>

          <!-- <div id="my_switcher" class="my_switcher setting-option">
              <ul>
                <li>
                  <a href="javascript: void(0);" data-theme="light" class="setColor light">
                    <img class="sun-image" src="/assets/images/icons/sun-01.svg" alt="Sun images">
                  </a>
                </li>
                <li>
                  <a href="javascript: void(0);" data-theme="dark" class="setColor dark">
                    <img class="Victor Image" src="/assets/images/icons/vector.svg" alt="Vector Images">
                  </a>
                </li>
              </ul>
            </div> -->
        </div>
      </div>
    </div>
    <div class="popup-mobile-menu">
      <div class="inner">
        <div class="header-top">
          <div class="logo logo-custom-css">
            <router-link class="logo-light" to="/"><img src="/assets/images/logo/logo-white.png?v=1.0.1"
                alt="nft-logo"></router-link>
            <router-link class="logo-dark" to="/"><img src="/assets/images/logo/logo-dark.png?v=1.0.1"
                alt="nft-logo"></router-link>
          </div>
          <div class="close-menu">
            <button class="close-button">
              <i class="feather-x"></i>
            </button>
          </div>
        </div>
        <nav>
          <!-- Start Mainmanu Nav -->
          <ul class="mainmenu">
            <li><router-link :class="$route.name === 'home' ? 'active' : ''" to="/">Mint</router-link></li>
            <li><router-link :class="$route.name === 'airdrop' ? 'active' : ''" to="/airdrop">Airdrop</router-link></li>
            <li><router-link :class="$route.name === 'account' ? 'active' : ''" to="/account">Account</router-link></li>
            <li><router-link :class="$route.name ==='marketplace'?'active':''"
                to="/marketplace">Marketplace</router-link></li>
          </ul>
          <!-- End Mainmanu Nav -->
        </nav>
      </div>
    </div>
  </header>


</template>
<script>

export default {

  data() {
    return {
      sticky: false,
      avatar: '',
      routePath: '/'
    }
  },
  methods: {

    handleScroll() {
      this.sticky = window.scrollY > 50;
    },
    showWalletModal() {
      this.$root.$emit('showWalletModal')
    },
    disconnectWallet() {
      this.$root.$emit('disconnectWallet')
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    console.log(this.$route)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
<style scoped>
.single-product-list .thumbnail {
  position: relative;
}

.single-product-list .thumbnail .thumbnail-image {
  background: var(--background-color-1);
  border-radius: 6px;
  border: 1px solid var(--color-border);
}

.single-product-list .thumbnail .thumbnail-image:hover {
  background: var(--color-primary);
}

.single-product-list .thumbnail .thumbnail-image img {
  padding: 3px;
  border-radius: 6px !important;
}

.single-product-list .thumbnail .badge {
  position: absolute;
  top: -5px;
  right: -2px;
  padding: 0 5px;
  display: inline-block;
  border-radius: 500px;
  line-height: 20px;
  min-width: 20px;
  height: 20px;
  color: white !important;
}

.mainmenu li a.active {
  color: var(--color-primary) !important;
}

.mainmenu li a:hover {
  color: white !important;
}

@media only screen and (max-width: 479px) {
  .haeder-default .header-left {
    align-items: center;
    flex-basis: auto !important;
  }

  .logo-thumbnail img {
    max-height: 50px !important;
    position: relative;
    top: 2px;
  }

  .haeder-default .header-right {
    justify-content: right !important;
    align-items: center !important;
  }
  .haeder-default .header-right .setting-option{
    margin: 0 5px;
  }
}
</style>