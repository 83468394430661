import Vue from 'vue'
import Vuex from 'vuex'
import createVuexAlong from "vuex-along";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wallet: {
      address: '',
      name:'',
      sign:'',
      token:'',
    },
    balance:0,
    invcode:'',
    bonusCount:0,
    chips:[0,0,0]
  },
  getters: {
    formatAddress:state => {
      if(state.wallet.address){
        return state.wallet.address.substring(0, 7) + '...' + state.wallet.address.substring(state.wallet.address.length - 7, state.wallet.address.length);
      }
      return '';
    }
  },
  mutations: {
    setWalletInfo(state, payload) {
      state.wallet = payload;
    },
    setInvcode(state, payload) {
      state.invcode = payload;
    },
    setBonusCount(state, payload) {
      state.bonusCount = payload;
    },
    setChips(state, payload) {
      state.chips = payload;
    },
    setBalance(state, payload) {
      state.balance = payload;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createVuexAlong({
    name: "vuex-along",
  })]
})
