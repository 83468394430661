<template>
  <div class="home">
    <!-- start banner area -->
    <div class="banner-area banner_xii  bg_image--24 bg_image with-down-shadow">
      <div class="container">
        <div class="row g-5 d-flex align-items-center flex-wrap">
          <!-- bannerleft -->
          <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12">
            <div class="left-thumbnail-image tilt">
              <img src="/assets/images/lock/locknft.png" alt="">
            </div>
          </div>
          <!-- banner left end -->

          <!-- banner right -->
          <div class="col-xxl-5 col-xl-6 col-lg-12 col-md-12">
            <h1 class="title mb--30">Mint The First SPL-403 Token</h1>
            <div class="place-bet-area into-banner mt_md--30 mt_sm--30">
              <div class="rn-bet-create">
                <div class="bid-list winning-bid" v-if="latest_mint">
                  <h6 class="title">Latest</h6>
                  <div class="top-seller-inner-one">
                    <div class="top-seller-wrapper">
                      <div class="thumbnail">
                        <a href="javascript:"><img :src="$common.getColorAvatar(latest_mint.address)" alt=""></a>
                      </div>
                      <div class="top-seller-content">
                        <span class="heighest-bid"><a href="javascript:" style="margin-left: 0;">{{
                  $common.formatAddress(latest_mint.address) }}</a></span>
                        <span class="count-number">
                          {{ latest_mint.mints }} mints
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bid-list left-bid" v-if="info.mint_time > 0">
                  <h6 class="title">Mint after</h6>
                  <div class="countdown mt--15" :data-date="info.deploy" :data-time="info.data_time">
                    <div class="countdown-container days">
                      <span class="countdown-value days-bottom">0</span>
                      <span class="countdown-heading days-top">Day</span>
                    </div>
                    <div class="countdown-container hours"><span class="countdown-value hours-bottom">0</span><span
                        class="countdown-heading hours-top">Hr's</span></div>
                    <div class="countdown-container minutes"><span class="countdown-value minutes-bottom">0</span><span
                        class="countdown-heading minutes-top">Min's</span></div>
                    <div class="countdown-container seconds"><span class="countdown-value seconds-bottom">0s</span><span
                        class="countdown-heading seconds-top">Sec</span></div>
                  </div>
                </div>
              </div>
              <div class="rn-pd-sm-property-wrapper into-banner">
                <div class="catagory-wrapper">
                  <!-- single property -->
                  <div class="pd-property-inner number-btn" :class="mintAmount === 1 ? 'active' : ''"
                    @click="numberBtnCclick(1)">
                    <span class="color-white value">1</span>
                  </div>
                  <!-- single property End -->
                  <!-- single property -->
                  <div class="pd-property-inner number-btn" :class="mintAmount === 100 ? 'active' : ''"
                    @click="numberBtnCclick(100)">
                    <span class="color-white value">100</span>
                  </div>
                  <!-- single property End -->
                  <!-- single property -->
                  <div class="pd-property-inner number-btn" :class="mintAmount === 1000 ? 'active' : ''"
                    @click="numberBtnCclick(1000)">
                    <span class="color-white value">1000</span>
                  </div>
                  <div class="pd-property-inner number-btn" :class="mintAmount === 10000 ? 'active' : ''"
                    @click="numberBtnCclick(10000)">
                    <span class="color-white value">10000</span>
                  </div>
                  <!-- single property End -->
                  <div class="pd-property-inner number-input-layout">
                    <a class="btn number-input-btn" @click="decBtnClick"><i class="feather-minus"></i></a>
                    <input type="number" class="form-control number-input" :min="1" :max="100000" v-model="mintAmount"
                      placeholder="">
                    <a class="btn number-input-btn" @click="incBtnClick"><i class="feather-plus"></i></a>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-primary mt--30" disabled v-if="info.mint_time > 0">Mint</button>
              <button type="button" class="btn btn-primary mt--30 mint-btn" @click="mint" :disabled="minting"
                v-else><span v-show="minting" class="spinner-border" role="status" aria-hidden="true"></span>
                <span class="visually-hidden">Loading...</span> Mint</button>
              <div class="mt--30">
                <div class="progress" role="progressbar" aria-valuenow="25" aria-label="Example 20px high"
                  aria-valuemax="100">
                  <div class="progress-bar bg-color-primary" :style="{ width: progress + '%' }">{{ progress }}%</div>
                </div>

              </div>

            </div>
            <div class="property-wrapper-flex d-flex">
              <div class="rn-pd-sm-property-wrapper into-banner mt--30">
                <h6 class="pd-property-title">
                  Overview
                </h6>
                <div class="catagory-wrapper">
                  <!-- single property -->
                  <div class="pd-property-inner">
                    <span class="color-body type">Name</span>
                    <span class="color-white value">{{ info ? info.name : '-' }}</span>
                  </div>
                  <!-- single property End -->
                  <!-- single property -->
                  <div class="pd-property-inner">
                    <span class="color-body type">Protocol</span>
                    <span class="color-white value">SPL-403</span>
                  </div>
                  <!-- single property End -->
                  <!-- single property -->
                  <div class="pd-property-inner">
                    <span class="color-body type">Total Supply</span>
                    <span class="color-white value">{{ info.total_supply ? info.total_supply.toLocaleString() : '0'
                      }}</span>
                  </div>
                  <div class="pd-property-inner">
                    <span class="color-body type">Price</span>
                    <span class="color-white value">{{ info.price ? info.price : '-' }}</span>
                  </div>
                  <!-- single property End -->
                  <div class="pd-property-inner">
                    <span class="color-body type">Minted</span>
                    <span class="color-white value">{{ info.minted ? info.minted.toLocaleString() : '0' }}</span>
                  </div>
                  <div class="pd-property-inner">
                    <span class="color-body type">Holders</span>
                    <span class="color-white value">{{ info.holders ? info.holders.toLocaleString() : '0' }}</span>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <!-- banner right end -->
        </div>
      </div>
    </div>
    <!-- End banner area -->

    <!-- Explore Style Carousel -->
    <div class="rn-live-bidding-area" v-if="latest_minted.length > 0">
      <div class="container">
        <div class="row mb--30 mt--20">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title mb--0 live-bidding-title">Latest Minted</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-one-slick slick-activation-03 slick-arrow-style-one rn-slick-dot-style slick-gutter-15">
              <!-- start single product -->
              <div class="single-slide-product" v-for="(item, index) in latest_minted">
                <div class="product-style-one">
                  <div class="card-thumbnail">
                    <a href="javascript:"><img src="/assets/images/lock/locknft.png" alt=""></a>
                  </div>
                  <div class="product-share-wrapper">
                    <div class="profile-share">
                      <div class="profile-img">
                        <a href="javascript:"><img :src="$common.getColorAvatar(item.address)" alt=""></a>
                      </div>
                      <a class="more-author-text" target="_blank" :href="'https://solscan.io/tx/' + item.signature">{{
                  $common.formatAddress(item.address) }}</a>
                    </div>
                    <!-- <div class="share-btn share-btn-activation dropdown">
                      <button class="icon" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg viewBox="0 0 14 4" fill="none" width="16" height="16" class="sc-bdnxRM sc-hKFxyN hOiKLt">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M3.5 2C3.5 2.82843 2.82843 3.5 2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2ZM8.5 2C8.5 2.82843 7.82843 3.5 7 3.5C6.17157 3.5 5.5 2.82843 5.5 2C5.5 1.17157 6.17157 0.5 7 0.5C7.82843 0.5 8.5 1.17157 8.5 2ZM11.999 3.5C12.8274 3.5 13.499 2.82843 13.499 2C13.499 1.17157 12.8274 0.5 11.999 0.5C11.1706 0.5 10.499 1.17157 10.499 2C10.499 2.82843 11.1706 3.5 11.999 3.5Z"
                            fill="currentColor"></path>
                        </svg>
                      </button>

                      <div class="share-btn-setting dropdown-menu dropdown-menu-end">
                        <button type="button" class="btn-setting-text share-text" data-bs-toggle="modal"
                          data-bs-target="#shareModal">
                          Share
                        </button>
                        <button type="button" class="btn-setting-text report-text" data-bs-toggle="modal"
                          data-bs-target="#reportModal">
                          Report
                        </button>
                      </div>

                    </div> -->

                  </div>
                  <a href="javascript:"><span class="product-name">{{ item.mints }} mints</span></a>
                  <span class="latest-bid">{{ $common.formatTime(item.createtime) }}</span>
                  <!-- <div class="bid-react-area">
                    <div class="last-bid">0.244wETH</div>
                    <div class="react-area">
                      <svg viewBox="0 0 17 16" fill="none" width="16" height="16" class="sc-bdnxRM sc-hKFxyN kBvkOu">
                        <path
                          d="M8.2112 14L12.1056 9.69231L14.1853 7.39185C15.2497 6.21455 15.3683 4.46116 14.4723 3.15121V3.15121C13.3207 1.46757 10.9637 1.15351 9.41139 2.47685L8.2112 3.5L6.95566 2.42966C5.40738 1.10976 3.06841 1.3603 1.83482 2.97819V2.97819C0.777858 4.36443 0.885104 6.31329 2.08779 7.57518L8.2112 14Z"
                          stroke="currentColor" stroke-width="2"></path>
                      </svg>
                      <span class="number">322</span>
                    </div>
                  </div> -->
                </div>
              </div>
              <!-- end single product -->



            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Explore Style Carousel End-->

    <!-- top top-seller start -->
    <div class="rn-top-top-seller-area nice-selector-transparent rn-section-gapTop" v-if="top_creators.length > 0">
      <div class="container">
        <div class="row  mb--30">
          <div class="col-12 d-flex">
            <h3 class="title mb--0 live-bidding-title">
              Top Creator</h3>
            <!-- <h3 class="title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">Top Creator</h3> -->
            <!-- <select>
                        <option data-display="1 day"> 1 day</option>
                        <option value="1">7 Day's</option>
                        <option value="2">15 Day's</option>
                        <option value="4">30 Day's</option>
                    </select> -->
          </div>
        </div>
        <div class="row g-5 top-seller-list-wrapper">

          <!-- start single top-seller -->
          <div class="col-5 col-lg-3 col-md-4 col-sm-6 top-seller-list" v-for="(item, index) in top_creators">
            <div class="top-seller-inner-one">
              <div class="top-seller-wrapper">
                <div class="thumbnail">
                  <a href="javascript:"><img :src="$common.getColorAvatar(item.address)" alt=""></a>
                </div>
                <div class="top-seller-content">
                  <a href="javascript:">
                    <h6 class="name">{{ $common.formatAddress(item.address, 5) }}</h6>
                  </a>
                  <span class="count-number">
                    {{ item.amount.toLocaleString() }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- End single top-seller -->


        </div>
      </div>
    </div>
    <!-- top top-seller end -->



  </div>
</template>

<script>
import Vue from 'vue'
const solanaWeb3 = require('@solana/web3.js')


export default {
  name: 'HomeView',
  data() {
    return {
      minting: false,
      mintAmount: 1,
      progress: 0,
      info: {
        mint_time: 1
      },
      latest_mint: null,
      latest_minted: [],
      top_creators: [],
    }
  },
  mounted() {
    this.loadData()
    window.imJs.m()

  },
  watch: {
    '$store.state.wallet.address'(val) {
      if (val) {
        this.loadData()
      }
    }
  },
  methods: {
    loadData() {

      this.$http.post('/api/spl403', {}).then(res => {
        if (res.data.code === 1) {
          const data = res.data.data
          this.info = data.data
          this.latest_mint = data.latest_mint

          this.$store.commit('setBonusCount', data.bonus)
          this.$store.commit('setChips', data.chips)
          this.$store.commit('setBalance', data.balance)

          window.$('.slick-activation-03').slick('unslick');

          this.latest_minted = data.latest_minted
          this.top_creators = data.top_creators



          this.$nextTick(() => {
            window.$(".countdown").length > 0 && window.$(".countdown").each(function () {
              window.$(this).countdown()
            })
            this.progress = this.info.minted > 0 ? Number((this.info.minted / this.info.total_supply * 100).toFixed(4)) : 0

            //this.$forceUpdate()
            window.$('.slick-activation-03').slick({
              infinite: true,
              slidesToShow: 5,
              slidesToScroll: 2,
              dots: false,
              arrows: true,
              cssEase: 'linear',
              adaptiveHeight: true,
              prevArrow: '<button class="slide-arrow prev-arrow"><i class="feather-arrow-left"></i></button>',
              nextArrow: '<button class="slide-arrow next-arrow"><i class="feather-arrow-right"></i></button>',
              responsive: [{
                breakpoint: 1399,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                  arrows: false,
                }
              }
              ]
            });
          })
          //window.$('.slick-activation-03').css('display', 'inline-block')


        }
      });
    },
    async mint() {
      if (this.info.state == 1 || this.info.minted >= this.info.total_supply || this.minting || this.info.mint_time >= 0) {
        //|| this.info.mint_time >= 0 
        return
      }

      if (this.mintAmount <= 0) {
        return
      }
      if (!this.$common.isPositiveInteger(this.mintAmount)) {
        this.$common.showToast('The value entered is invalid.')
        return
      }
      const connection = new solanaWeb3.Connection(this.$rpcurl)
      this.minting = true

      try {
        let provider;
        if (typeof window.okxwallet !== 'undefined' && this.$store.state.wallet.name == 'okx') {
          provider = window.okxwallet.solana
        } else if (this.$store.state.wallet.name == 'phantom' && 'phantom' in window) {
          provider = window.phantom?.solana
        }
        if (!provider) {
          this.$root.$emit('showWalletModal')
          this.minting = false
          return
        }
        const balance = await connection.getBalance(provider.publicKey)
        let lamports = this.info.price * this.mintAmount
        if (balance <= 0 || (balance > 0 && balance / 1000000000 < lamports)) {
          this.$common.showToast('Insufficient balance', 'error')
          this.minting = false
          return
        }
        // console.log(`Balance for account ${provider.publicKey.toString()}: ${balance/ 1000000000}`)

        //console.log(provider.publicKey.toBase58())
        // console.log(this.info)
        // console.log(this.mintAmount)
        //console.log((this.info.price * 1000000000) * this.mintAmount)
        const transaction = new solanaWeb3.Transaction().add(
          solanaWeb3.SystemProgram.transfer({
            fromPubkey: provider.publicKey,
            //toPubkey: provider.publicKey,
            toPubkey: this.info.address,
            //lamports: 277000
            lamports: (this.info.price * 1000000000) * this.mintAmount
          })
        )
        //console.log(111)
        transaction.feePayer = provider.publicKey

        transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash

        const { signature } = await provider.signAndSendTransaction(transaction)
        //console.log(signature)
        if (signature) {
          this.$common.showToast('Minted successfully')
        } else {
          this.$common.showToast('signAndSendTransaction failed', 'error')
        }
        this.minting = false

      } catch (e) {
        this.$common.showToast(e.message, 'error')
        this.minting = false
      }
    },
    numberBtnCclick(value) {
      this.mintAmount = value
    },
    decBtnClick() {
      this.mintAmount--
      if (this.mintAmount <= 0) {
        this.mintAmount = 1
      }
    },
    incBtnClick() {
      this.mintAmount++
      if (this.mintAmount > 100000) {
        this.mintAmount = 100000
      }
    }
  },
  created() {

  },

}
</script>

<style scoped>
.top-seller-inner-one .top-seller-wrapper .top-seller-content a h6 {
  font-size: 15px !important;
}

.mint-btn {
  display: flex;
  justify-content: center;
}

.mint-btn span {
  margin-right: 10px;
}

.rn-top-top-seller-area {
  padding-bottom: 100px;
}

.number-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 55px;
  cursor: pointer;
  margin: 7px 4px !important;
}

.number-input {
  background: transparent;
  color: white;
  font-size: 14px;
  text-align: center;
  margin: 0 5px;
}

.number-input-btn {
  padding: 5px 7px !important;
  width: auto !important;
}

.number-input-btn:hover {
  color: var(--color-primary);
}

.number-input-btn i {
  font-size: 14px !important;
}

.number-btn:hover,
.number-btn.active {
  background: var(--color-primary-alta);
}

.number-input-layout {
  display: flex !important;
  padding: 5px !important;
}

.rn-pd-sm-property-wrapper .property-wrapper .pd-property-inner,
.rn-pd-sm-property-wrapper .catagory-wrapper .pd-property-inner {
  padding: 10px !important;
}

@media only screen and (max-width: 767px) {
  .number-input-layout {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
