import axios from 'axios'
import Qs from 'qs'
import { simenc } from "./simencrypt";
import Vue from 'vue'
// axios.defaults.baseURL = 'http://127.0.0.1:8099'
axios.defaults.baseURL = 'https://api.spl403.com';
axios.interceptors.request.use(
    config => {

        if (config.method === 'post') {

            let token = ''
            let address = ''
            try {
                let s = localStorage.getItem("vuex-along")
                if (s) {
                    let store = JSON.parse(s)
                    if (store) {

                        token = store.root.wallet?.sign
                        if (token && !config.data.hasOwnProperty('sign')) {
                            config.data = Object.assign({}, config.data, { sign: simenc(token) })
                        }
                        address = store.root.wallet?.address
                        if (address && !config.data.hasOwnProperty('address')) {
                            config.data = Object.assign({}, config.data, { address: simenc(address) })
                        }
                        //console.log(config)
                    }
                }
            } catch (e) {
                console.error(e.message)
            }


            config.timeout = 300000
            //config.headers['Authorization'] = token
            return config;
        }


    },
    error => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(response => {
    if (response && response.data && response.data.code === 0) {
        if (response.data.msg) {
            Vue.prototype.$toast.open({
                message: response.data.msg,
                type: "error",
            })
        }
    }
    return response;
}, error => {
    // if (error.response) {
    //     let res = error.response.data;
    //     if (res.code === 401) {
    //         window.toastr.error(res.msg)
    //         let s = localStorage.getItem("vuex-along")
    //         if (s) {
    //             let store = JSON.parse(s)
    //             if (store && store.root.user) {
    //                 store.root.user.token = ''
    //                 localStorage.setItem('vuex-along', JSON.stringify(store))
    //             }
    //         }
    //         setTimeout(function () {
    //             window.location.href = '/login'
    //         }, 1500)
    //     }
    // }
    return Promise.resolve(error.response)
})
const http = {}
http.post = (url, data) => {
    return axios({
        method: 'post',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    })
}
http.uploadFile = (url, file) => {
    return axios({
        method: 'post',
        url: url,
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
http.get = (url, params) => {
    return axios({
        method: 'get',
        url: url,
        params,
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
}
export default http

