<template>
  <!-- Modal -->
  <div class="rn-popup-modal share-modal-wrapper modal fade" id="walletModal" tabindex="-1" aria-hidden="true">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i data-feather="x"></i></button>
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content share-wrapper">
        <div class="modal-header share-area">
          <h5 class="modal-title">Connect your wallet</h5>
        </div>
        <div class="modal-body">
          <ul class="social-share-default">
            <li><a @click="choosewallet('okx')"><span class="icon"><img src="/assets/images/logo/okx.png"></span><span
                  class="text">OKX Web3</span></a></li>
            <li><a @click="choosewallet('phantom')"><span class="icon"><img
                    src="/assets/images/logo/phantom.svg"></span><span class="text">Phantom</span></a></li>
            <!-- <li><a href="#"><span class="icon"><i data-feather="linkedin"></i></span><span class="text">linkedin</span></a></li>
                        <li><a href="#"><span class="icon"><i data-feather="instagram"></i></span><span class="text">instagram</span></a></li>
                        <li><a href="#"><span class="icon"><i data-feather="youtube"></i></span><span class="text">youtube</span></a></li> -->
          </ul>
          <div class="mt--30 text-center" v-if="loading">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
const solanaWeb3 = require('@solana/web3.js')
import bs58 from 'bs58'
import { Buffer } from 'buffer'
import { simenc } from "@/utils/simencrypt";
const signMessage = `Sign and verify that you are the owner of the account, this request will not trigger a blockchain transaction.`;
export default {

  data() {
    return {
      loading: false,
      userClick: false
    }
  },
  methods: {

    async authSign(address, sign) {
      const invcode = this.$store.state.invcode
      let params = { address: simenc(address), sign: simenc(sign) }
      if (invcode && address != invcode) {
        params = { address: simenc(address), sign: simenc(sign), invcode: simenc(invcode) }
      }
      const { data } = await this.$http.post('/api/spl403/auth', params)
      return data
    },

    choosewallet(name) {
      if (this.loading) {
        return;
      }
      let walletInfo = this.$store.state.wallet
      walletInfo.name = 'name'
      this.$store.commit('setWalletInfo', walletInfo)
      if (name === 'okx') {
        if (typeof window.okxwallet !== 'undefined') {
          this.loading = true;

          try {
            this.userClick = true;
            const provider = window.okxwallet.solana;
            provider.connect();
          } catch (error) {
            this.$toast.open({
              message: `${error.message}`,
              type: "error",
            })
            this.loading = false
            this.userClick = false;
          }

          console.log('OKX is installed!')


        } else {
          window.open('https://www.okx.com/web3', '_blank')
        }
      } else {
        const provider = window.phantom?.solana
        if (provider?.isPhantom) {
          this.loading = true;

          console.log('phantom is installed.')
          provider.connect().catch((error) => {
            this.loading = false;
            this.$toast.open({
              message: `${error}`,
              type: "error",
            })
          })
        } else {
          window.open('https://phantom.app/', '_blank')
        }
      }
    },




    async disconnectWallet() {
      let walletInfo = this.$store.state.wallet
      if (walletInfo.name === 'phantom') {
        const provider = window.phantom?.solana
        provider.disconnect()
      }
      walletInfo.address = ''
      walletInfo.name = ''
      walletInfo.sign = ''
      walletInfo.balance = 0
      this.userClick = false
      this.$store.commit('setWalletInfo', walletInfo)
      this.$store.commit('setBonusCount', 0)
      this.$store.commit('setChips', [0, 0, 0])
    },

    handleload() {
      console.log(window.phantom)
      if ('phantom' in window) {
        const provider = window.phantom?.solana
        if (provider?.isPhantom) {
          console.log('phantom is installed.')

          let walletInfo = this.$store.state.wallet
          if (walletInfo.name === 'phantom' && walletInfo.address) {
            provider.connect().catch((error) => {
              console.log(error)
            })
          }


          provider.on('connect', (publicKey) => {
            let walletInfo = this.$store.state.wallet
            if (!walletInfo.sign) {
              const encodedMessage = new TextEncoder().encode(signMessage);
              provider.signMessage(encodedMessage, "utf8").then((signature) => {
                const sign = bs58.encode(Buffer.from(signature.signature))

                this.authSign(publicKey.toBase58(), sign).then((data) => {
                  this.loading = false;
                  window.$('#walletModal').modal('hide')
                  if (data.code === 0) {
                    this.disconnectWallet()
                    this.$common.showToast('Signature verification failed and try again', 'error')
                  } else {
                    walletInfo.address = publicKey.toBase58()
                    walletInfo.name = 'phantom'
                    walletInfo.sign = sign
                    this.$store.commit('setWalletInfo', walletInfo)
                  }
                })


              }).catch((error) => {
                this.loading = false;
                this.$toast.open({
                  message: `${error}`,
                  type: "error",
                })
              });
            } else {
              window.$('#walletModal').modal('hide')
              this.loading = false;
            }
            console.log('connected=>' + publicKey)
          })
          provider.on('disconnect', () => {
            //console.log('disconnect!')
            let walletInfo = this.$store.state.wallet
            walletInfo.address = ''
            walletInfo.name = ''
            walletInfo.sign = ''
            this.$store.commit('setWalletInfo', walletInfo)
          })
          provider.on('accountChanged', (publicKey) => {
            if (publicKey) {
              let walletInfo = this.$store.state.wallet
              if (walletInfo.name === 'phantom') {
                this.$toast.open({
                  message: `Signature verification in phantom wallet`,
                  type: "info",
                })

                const encodedMessage = new TextEncoder().encode(signMessage);
                provider.signMessage(encodedMessage, "utf8").then((signature) => {
                  const sign = bs58.encode(Buffer.from(signature.signature))

                  this.authSign(publicKey.toBase58(), sign).then((data) => {
                    if (data.code === 0) {
                      this.disconnectWallet()
                      this.$common.showToast('Signature verification failed and try again', 'error')
                    } else {
                      walletInfo.address = publicKey.toBase58()
                      walletInfo.name = 'phantom'
                      walletInfo.sign = sign
                      this.$store.commit('setWalletInfo', walletInfo)
                    }
                  })
                }).catch((error) => {
                  this.$toast.open({
                    message: `${error}`,
                    type: "error",
                  })
                });

              }
            } else {
              provider.connect().catch((error) => {
                console.log(error)
              })
            }
          })
        }
      }

      if (typeof window.okxwallet !== 'undefined') {
        const okxProvider = window.okxwallet.solana;
        let connect = false
        okxProvider.on('connect', (publicKey) => {
          if (connect || !this.userClick) {
            return;
          }
          //console.log('connected=>' + publicKey)
          connect = true
          let walletInfo = this.$store.state.wallet
          if (!walletInfo.sign) {

            const encodedMessage = new TextEncoder().encode(signMessage);
            okxProvider.signMessage(encodedMessage, "utf8").then((signature) => {
              const sign = bs58.encode(Buffer.from(signature.signature))



              this.authSign(publicKey.toBase58(), sign).then((data) => {
                this.loading = false;
                connect = false
                window.$('#walletModal').modal('hide')
                if (data.code === 0) {
                  this.disconnectWallet()
                  this.$common.showToast('Signature verification failed and try again', 'error')
                } else {
                  walletInfo.address = publicKey.toBase58()
                  walletInfo.name = 'okx'
                  walletInfo.sign = sign
                  this.$store.commit('setWalletInfo', walletInfo)
                }
              })

            }).catch((error) => {
              connect = false
              this.loading = false;
              this.$toast.open({
                message: `${error.message}`,
                type: "error",
              })
            });
          } else {
            window.$('#walletModal').modal('hide')
            this.loading = false;
            connect = false
          }
        })
        okxProvider.on('disconnect', () => {
          console.log('disconnected!')
          let walletInfo = this.$store.state.wallet
          if (walletInfo.name === 'okx') {
            walletInfo.address = ''
            walletInfo.name = ''
            walletInfo.sign = ''
            this.$store.commit('setWalletInfo', walletInfo)
          }

        })
        okxProvider.on('accountChanged', (publicKey) => {
          if (publicKey) {
            let walletInfo = this.$store.state.wallet
            if (walletInfo.name === 'okx') {
              this.$toast.open({
                message: `Signature verification in okx wallet`,
                type: "info",
              })
              //walletInfo.address = publicKey.toBase58()
              const encodedMessage = new TextEncoder().encode(signMessage);
              okxProvider.signMessage(encodedMessage, "utf8").then((signature) => {
                const sign = bs58.encode(Buffer.from(signature.signature))

                //console.log(`Switched to account ${publicKey.toBase58()}`)
                //this.authSign();
                this.authSign(publicKey.toBase58(), sign).then((data) => {
                  if (data.code === 0) {
                    this.disconnectWallet()
                    this.$common.showToast('Signature verification failed and try again', 'error')
                  } else {
                    walletInfo.address = publicKey.toBase58()
                    walletInfo.name = 'okx'
                    walletInfo.sign = sign

                    this.$store.commit('setWalletInfo', walletInfo)
                  }
                })

              }).catch((error) => {
                this.$toast.open({
                  message: `${error.message}`,
                  type: "error",
                })
              });
            }
          } else {
            // Attempt to reconnect to OKX wallet
            okxProvider.connect().catch((error) => {
              // Handle connection failure
            })
          }
        })

      }
    },

  },
  mounted() {
    window.$('#walletModal').on('hidden.bs.modal', () => {
      this.loading = false;
    });
    //this.handleload()
  },
  created() {
    this.$root.$on('showWalletModal', () => {
      window.$('#walletModal').modal('show')
    })
    this.$root.$on('disconnectWallet', () => {
      this.disconnectWallet()
    })


    window.addEventListener('load', this.handleload)

  },
  beforeDestroy() {
    this.$root.$off('showWalletModal')
    this.$root.$off('disconnectWallet')
    window.removeEventListener('load', this.handleload)
  },

}
</script>
<style scoped>
.social-share-default img {
  width: 24px;
}
</style>