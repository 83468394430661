const Identicon = require('identicon.js');
import sha256 from 'js-sha256';
import Vue from 'vue'
const web3 = require("@solana/web3.js");

export default {

    //验证solana公钥
    isSOLPublickey(key) {
        try {
            new web3.PublicKey(key);
            return true;
        } catch (err) {
            return false;
        }
    },
    //根据地址随机生成头像
    getColorAvatar(address, size = 64, transparent = 0) {
        if (!address) {
            return `data:image/png;base64,`
        }

        // create a SHA256 hash object
        let hashHex = sha256(address);

        // get the first 6 characters of the hash
        let hashSub = hashHex.substring(0, 6);

        // split the hash into three parts and convert them to integers
        let r = parseInt(hashSub.substring(0, 2), 16);
        let g = parseInt(hashSub.substring(2, 4), 16);
        let b = parseInt(hashSub.substring(4, 6), 16);

        const options = {
            foreground: [r, g, b, 255],               // rgba black
            background: [255, 255, 255, transparent],         // rgba white
            margin: 0.25,                              // 20% margin
            size: size,                                // 420px square
            format: 'png'                             // use SVG instead of PNG
        }

        const data = new Identicon(address, options).toString();
        return `data:image/png;base64,${data}`
    },

    copyText(text, container = null) {
        if (container) {
            Vue.prototype.$copyText(text, container)
        } else {
            Vue.prototype.$copyText(text)
        }
        console.log(text);
        Vue.prototype.$toast.open({
            message: `Copied to clipboard`,
            type: "success",
        })
    },

    //消息弹出框
    showToast(message, type = 'success') {
        Vue.prototype.$toast.open({
            message: message,
            type: type,
        })
    },

    // 格式化日期
    formatDate(timestamp, format) {
        // 创建一个新的日期对象使用时间戳
        const date = new Date(timestamp * 1000);

        let formattedDate = format
            .replace('y', date.getFullYear())
            .replace('m', String(date.getMonth() + 1).padStart(2, '0'))
            .replace('d', String(date.getDate()).padStart(2, '0'));

        return formattedDate;
    },
    formatTime(timestamp) {
        const date = new Date(timestamp * 1000);
        const year = date.getFullYear();
        const month = date.getMonth() + 1
        const day = date.getDate()
        const hours = date.getHours()
        const minutes = date.getMinutes()

        const y = year.toString().substring(2, 4)
        return `${day}/${month}/${y} ${hours}:${minutes}`
    },

    // 判断是否为正整数
    isPositiveInteger(value) {
        // 使用正则表达式测试值是否匹配正整数的模式。
        // 注意，0不被视为正整数
        let pattern = /^[1-9]\d*$/;
        return pattern.test(value);
    },

    //判断客户端类型
    isMobile() {
        return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    },

    formatAddress(address, len = 7) {
        if (!address) {
            return 'undefind'
        }
        return address.length <= len ? address : address.substring(0, len) + '...' + address.substring(address.length - len, address.length);
    }
}