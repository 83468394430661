import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import common from './utils/common.js'
import http from './utils/axios.js'
import * as buffer from "buffer";
window.Buffer = buffer.Buffer;
Vue.config.productionTip = false
Vue.use(VueToast, {
  position: 'top',
  dismissible: true,
  duration: 4000,
})

Vue.use(VueClipboard);
Vue.prototype.$common = common
Vue.prototype.$http = http
Vue.prototype.$rpcurl = 'https://nd-708-250-894.p2pify.com/22a0382492ed57d47c27fe4c03cab200'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
